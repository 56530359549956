<template>
  <div>
    <header>
        <div class="container">
            <nav class="navbar-dark bg-transparenet">
                <a class="navbar-brand daum-wm-title" href="#">
                    부동산상사
                </a>
                <span class="navbar-text ml-auto d-none d-sm-inline-block"></span>
                <span class="navbar-text d-none d-sm-inline-block"></span>
            </nav>
        </div>
    </header>
    <main class="my-auto">
        <div class="container" style="padding-top: 150px">
            <h1 class="page-title">{{ msg }}</h1>
            <p class="page-description">새로운 부동산 패러다임을 이끌어 갑니다.</p>
        </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  props: {
    msg: String
  }
}
</script>

<style>
body {
  font-family: 'Karla', sans-serif;
  background-image: url("/images/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
  padding-bottom: 75px;
}

@media (min-width: 768px) {
  body {
    padding-bottom: 110px;
  }
}

.navbar-brand {
  margin-right: 0;
}

.navbar-brand img {
  height: 21px;
}

.navbar-dark {
  padding-top: 35px;
  padding-bottom: 35px;
}

.navbar-dark .navbar-text {
  color: #ffffff;
  opacity: 1;
  padding: 0 20px;
  border-right: 1px solid #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.navbar-dark .navbar-text:last-child {
  border-right: 0;
}

@media (max-width: 767px) {
  .navbar-dark {
    padding-bottom: 0;
  }
}

.page-title {
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  .page-title {
    font-size: 35px;
    margin-bottom: 14px;
  }
}

.page-description {
  max-width: 550px;
  font-size: 18px;
  margin-bottom: 59px;
}

@media (max-width: 767px) {
  .page-description {
    font-size: 14px;
  }
}

p {
  font-size: 14px;
  margin-bottom: 21px;
}

.footer-social-links .social-link {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #000000;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;
}

.footer-social-links .social-link:last-child {
  margin-right: 0;
}

.footer-social-links .social-link:hover {
  text-decoration: none;
  background-color: #000000;
  color: #ffffff;
}
/*# sourceMappingURL=bd-coming-soon.css.map */
</style>

