<template>
  <div>
    <ComingSoon msg="We're coming soon"/>
  </div>
</template>

<script>
import ComingSoon from '../components/ComingSoon.vue'
export default {
  components: {
    ComingSoon,
  },
  data: () => ({}),
};
</script>